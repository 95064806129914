//=====================
//       Fonts
//=====================
$base-font-size: 10px;

//=====================
//       Navs
//=====================
$nav-mobile-height: 6.5rem;
$nav-desktop-width: 20rem;

//=====================
//      Inputs
//=====================
$inputs-padding: 1.2rem 1rem;
$input-border-radius: 12px;
