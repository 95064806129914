@use '@/scss' as *;

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: px-to-rem(8px);
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(16px);
}

.item {
  display: flex;

  [role='icon'] {
    margin-right: px-to-rem(12px);
  }
}

.button {
  margin-top: px-to-rem(8px);
}
