$gr-primary-to-background: linear-gradient(
  to right bottom,
  rgb(99 31 254 / 20%),
  rgb(125 87 246 / 10%),
  rgb(149 126 233 / 10%),
  rgb(172 162 217 / 20%),
  rgb(196 196 196 / 30%)
);
$gr-radial-purple: radial-gradient(50% 50% at 50% 50%, rgba(99, 31, 254, 0.12) 0%, rgba(99, 31, 254, 0) 100%);
$gr-radial-purple-dark: radial-gradient(
  50% 50% at 50% 50%,
  rgba(99, 31, 254, 0.22) 0%,
  rgba(99, 31, 254, 0) 100%
);
$gr-radial-yellow: radial-gradient(
  50% 50% at 50% 50%,
  rgba(255, 227, 94, 0.16) 0%,
  rgba(255, 227, 94, 0) 100%
);

$gr-primary-linear: linear-gradient(271.23deg, #631ffe -22.98%, #946cf1 126.04%);
$gr-disabled-linear: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
