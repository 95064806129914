@use '@/scss' as *;

.wrapper {
  height: 100vh;
  background-color: $background;
  padding: px-to-rem(24px) px-to-rem(16px);

  @include media(tablet) {
    @include flex(center, flex-start, column, nowrap);
  }

  @include media(desktop) {
    padding: px-to-rem(24px) 15vw;
  }

  img {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
    object-fit: cover;
  }

  i[role='network-icon'] {
    @include horizontal-center();
    position: fixed;
    display: block;
    bottom: px-to-rem(24px);

    @include media(tablet) {
      @include vertical-center();
      position: fixed;
      bottom: inherit;
      right: px-to-rem(100px);
      left: inherit;
    }

    // If short screen
    @media screen and (max-height: 475px) {
      opacity: 0.3;
      left: 50%;
      top: 50%;
      bottom: inherit;
      transform: translate(-50%, -50%);

      @include media(tablet) {
        left: inherit;
        right: px-to-rem(100px);
      }
    }
  }

  h1 {
    @include heading6-typeface();
    margin-top: px-to-rem(72px);

    @include media(tablet) {
      @include heading4-typeface();
    }
  }

  p {
    color: $textOnBackgroundMedium;
    margin-bottom: px-to-rem(16px);
    @include bodySecond-typeface();
    max-width: 40rem;

    @include media(tablet) {
      margin-bottom: px-to-rem(24px);
    }
  }

  .buttonRow {
    display: flex;
    gap: 1.2rem;
  }
}

.iconWrapper {
  text-align: center;
  @include media(tablet) {
    position: absolute;
    top: 1rem;
    left: px-to-rem(16px);
  }

  @include media(desktop) {
    left: 15vw;
  }

  i {
    color: $primary;
  }
}
