@use '@/scss' as *;

.wrapper {
  background-color: $backgroundPaper;
  border-radius: px-to-rem(16px);
}

.container {
  border-radius: px-to-rem(12px);
  padding-bottom: px-to-rem(16px);

  width: 100%;
  [role='asset-search-input'] {
    width: 100%;
  }

  @include media(desktop) {
    &.scroll {
      @include hide-scrollbar();
      height: 48rem;
      overflow: auto;
    }
  }
}

.caption {
  display: block;
  margin: 1.6rem 0 0.8rem;
  color: $textOnBackgroundLight;
  @include caption-typeface();
}

.list {
  width: 100%;
}

.wrapperSkeleton {
  padding: 0 px-to-rem(16px);

  @include media(tablet, max) {
    padding: 0 px-to-rem(12px);
  }
}

.skeleton {
  margin-top: px-to-rem(8px);
  position: relative;
  display: flex;
  gap: 0.8rem;
  padding: px-to-rem(16px) 0;

  .body {
    flex: 1;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.6rem;

      &:last-of-type {
        align-items: end;
      }
    }
  }
}

.title {
  margin-bottom: px-to-rem(4px);
  padding: px-to-rem(24px) px-to-rem(24px) 0 px-to-rem(24px);

  @include media(tablet, max) {
    text-transform: uppercase;
    padding: px-to-rem(12px) px-to-rem(12px) 0 px-to-rem(12px);
  }
}

.exploreMenu {
  @include hide-scrollbar();
  display: flex;
  gap: px-to-rem(8px);
  overflow-x: scroll;
  padding: 0 px-to-rem(24px);
  margin: px-to-rem(16px) 0;

  @include media(tablet, max) {
    margin: px-to-rem(12px) 0;
    padding: 0 px-to-rem(12px);
  }

  li {
    @include bodySecond-typeface();
    padding: px-to-rem(8px) px-to-rem(16px);
    border-radius: px-to-rem(8px);
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.5s;
  }

  .active,
  li:hover {
    color: $textOnTertiary;
    background-color: $buttonOnPressBackground;
  }
}

.wrapperAssetList {

  // This gives the section bottom padding (16px or 12px),
  // but we need to remove the list's built-in bottom padding of 4px.
  padding-bottom: px-to-rem(16px-4px);
  @include media(tablet, max) {
    margin-bottom: px-to-rem(12px-4px);
  }
}

.failedToLoad {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 px-to-rem(16px) px-to-rem(16px);

  .title {
    @include subtitleThird-typeface();
    margin: px-to-rem(4px) 0;
    color: $textOnSecondary;
    padding: 0 px-to-rem(16px);
  }

  .description {
    text-align: center;

    @include media(tablet, max) {
      padding: 0;
    }
  }
}

.footer {
  text-align: center;
}

// Prevents asset items from being cut off too soon when the list needs to be scrolled
.noFooterPadding {
  padding-bottom: 0;
}

.wrapperSearch {
  padding: px-to-rem(12px);

  @include media(tablet, max) {
    padding: px-to-rem(24px);
  }

  .search {
    background-color: transparent;
    font-size: px-to-rem(16px);
  }
}
