@use '@/scss' as *;

.wrapper {
  gap: px-to-rem(8px);
  z-index: 1;
}

.fullWidth {
  width: 100%;
}

.onlyIcon {
  gap: 0;
}

.primary {
  background: $primary;
  @include elevationx6;

  @media (hover: hover) {
    &:hover {
      background: $primaryx400;
    }
  }

  [role='icon'] {
    color: $backgroundPaper;
  }
}

.colorPrimary {
  color: $primary;
  &.secondary {
    &.onlyIcon {
      border: 1px solid $primaryx100;
      @media (hover: hover) {
        &:hover {
          border: 1px solid $primary;
        }
      }
    }

    &:not(.onlyIcon) {
      border: 1px solid $primary;
      @media (hover: hover) {
        &:hover {
          border: 1px solid $primaryx200;
        }
      }
    }
  }

  &.tertiary {
    @media (hover: hover) {
      &:hover {
        color: $primaryx400;
      }
    }
  }

  &.backgroundColorPaper {
    background-color: $backgroundPaper;
    box-shadow: none;

    span {
      color: $textOnTertiary !important;
    }
  }
}

.colorWhite {
  color: $textOnPrimary;

  [role='icon'] {
    color: $textOnPrimary !important;
  }

  &.secondary {
    border: 1px solid $backgroundPaper;

    @media (hover: hover) {
      &:hover {
        border: 1px solid $primaryx200;
      }
    }
  }
  &.backgroundColorPrimary {
    background-color: $primary;
  }
}

.icon {
  padding: 1em;
  height: unset;
  min-height: unset;
}

.large {
  padding: 0 3.5em;
  height: px-to-rem(64px);
  min-height: px-to-rem(64px);

  span:not([role='icon']) {
    @include button-typeface();
  }
}

.medium {
  height: px-to-rem(56px);
  min-height: px-to-rem(56px);
  padding: 0 3.5em;

  span:not([role='icon']) {
    @include button-typeface();
  }
}

.small {
  padding: 0 3.5em;

  span:not([role='icon']) {
    @include button-typeface();
  }

  height: px-to-rem(48px);
  min-height: px-to-rem(48px);
}

.xsmall {
  font-size: px-to-rem(12px);
  font-weight: 700;
  line-height: px-to-rem(18px);
  letter-spacing: px-to-rem(0.1px);
  padding: 0 px-to-rem(12px);
  height: px-to-rem(40px);
  min-height: px-to-rem(40px);
}

.noShadow {
  box-shadow: none;
}
