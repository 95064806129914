@use '@/scss/variables' as *;
@use '@/scss/tools' as *;

.container {
  min-height: calc(100dvh - $nav-mobile-height);
  padding-bottom: $nav-mobile-height;
  width: 100%;
  position: relative;
  background: $background;

  &.hideNavBar {
    min-height: 100dvh;
    padding-bottom: 0;
  }

  @include media(desktop) {
    padding-left: 25vw;
    padding-bottom: 0;

    &:after,
    &:before {
      content: '';
      width: 70vw;
      height: 70vw;
      position: fixed;
      z-index: 1;
    }

    &:after {
      background: $gr-radial-purple;
      top: -50vh;
      left: -10vw;
    }

    &:before {
      background: $gr-radial-yellow;
      bottom: -30vh;
      right: -20vw;
    }
  }

  @include media(screen) {
    padding-left: 22vw;
  }

  @media (min-width: 1600px) {
    padding-left: 17vw;
  }
}

.main {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100dvh - $nav-mobile-height);

  &.hideNavBar {
    min-height: 100dvh;
  }

  @include media(tablet) {
    padding: 4rem 6.4rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: px-to-rem(16px);
    z-index: 2;

    &.centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include media(desktop) {
    padding: 4rem 0;
    max-width: 65rem;
    margin: 0 auto;
  }
}

.mainNewUi {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(24px);
  padding: px-to-rem(24px) px-to-rem(24px);

  @include media(tablet, max) {
    gap: px-to-rem(16px);
    padding: px-to-rem(16px) px-to-rem(16px);
  }

  @include media(desktop) {
    margin: 0rem auto;
  }
}
