// imports base styles
@use './generic';
// imports prime overwrite
@use './primereact/theme';
// imports tools and variables
@use '@/scss' as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

html,
body {
  padding: 0;
  margin: 0;
  // default html font-size browser value: 16px = 100% --> 1rem
  // our custom value: 10px = 62.5% --> 1rem
  font-size: 62.5% !important;
  font-style: normal;
  color: $textOnBackground;
  background-color: $background;
  min-height: 100vh;
  min-height: 100svh;
  overflow-x: clip;
  @include hide-scrollbar();
}

div {
  scrollbar-width: thin;
  scrollbar-color: $primary transparent;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 18px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: block;
  transition: all 0.3s;
}

ol,
ul {
  list-style: none;
}

button {
  outline: none;
  background: none;
  border: none;
}

h1 {
  @include heading5-typeface();
  margin-bottom: 1em;
}

h2 {
  font-size: px-to-rem(16px);
  font-weight: 700;
}

h3 {
  font-size: px-to-rem(14px);
  font-weight: 700;
}

h4 {
  @include heading7-typeface();
}

p {
  @include bodySecond-typeface();
}

// Hide input number arrows ⬇️
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
}

.p-chip {
  background-color: transparent;
  color: $secondary;
  border-color: $secondary;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 4px 16px 4px 16px;
}
.p-chip .p-chip-text {
  color: $secondary;
  @include subtitleSecond-typeface();
}
